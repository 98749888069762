@import "fonts";

// Core
@import "~bootstrap/scss/functions";
@import "custom/functions";

@import "custom/variables";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins";

// Bootstrap components

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
// @import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
// @import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
// @import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
// @import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

// Custom components
@import "core";

@import "custom/reboot.scss";
@import "custom/global.scss";
@import "custom/utilities.scss";

// @import "custom/accordion.scss";
// @import "custom/alerts.scss";
// @import "custom/avatars.scss";
@import "custom/badge.scss";
@import "custom/buttons.scss";
@import "custom/card.scss";
// @import "custom/carousel.scss";
@import "custom/close.scss";
@import "custom/custom-forms.scss";
// @import "custom/dropdown.scss";
@import "custom/footer.scss";
@import "custom/forms.scss";
@import "custom/grid.scss";
@import "custom/icons.scss";
@import "custom/input-group.scss";
// @import "custom/list-group.scss";
// @import "custom/modal.scss";
@import "custom/nav.scss";
@import "custom/navbar.scss";
// @import "custom/pagination.scss";
@import "custom/popover.scss";
// @import "custom/progress.scss";
@import "custom/section.scss";
@import "custom/separator.scss";
@import "custom/type.scss";

// Vendor

// @import "custom/vendor/bootstrap-datepicker.scss";
// @import "custom/vendor/headroom.scss";
// @import "custom/vendor/nouislider.scss";

// React differences

@import "react/react-differences.scss";

@import "main";

.tooltip {
  z-index: 2000 !important;
}
